import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import Swiper from 'react-id-swiper'

import { media, useBreakpoint } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth } from '../../../styles/global'
import { useMount } from 'react-use';
import { Link } from 'gatsby';

const BlockSlider = (props) => {
	const {slides, sliderStyles, controls} = props;
	const [caption, updateCaption] = useState(null);
	const [activeIndex, updateActiveIndex] = useState(0);
	const [swiper, updateSwiper] = useState(null);
	const canLoop = slides && slides.length > 1;
	
	const swiperParams = {
		getSwiper: updateSwiper,
		loop: canLoop,
		...props.sliderParams
	}

	useMount(() => {
		onSlideChange()
	})

	useEffect(() => {
		if (swiper) {
			swiper.on('slideChange', onSlideChange);
		}
	}, [swiper])

	const onSlideChange = () => {
		updateActiveIndex(swiper ? (swiper.realIndex) : 0)
	}

	const wrapLink = (link, children) => {
		return (
			<Link
				to={link}
			>
				{children}
			</Link>
		)
	}
	
	const renderSlides = () => {
		if (!slides || !slides.length) return;

		return slides.map((item, i) => {
			if (!item.image) return;
			
			return (
				<Slide
					key={i}
				>
					<Image 
						className={'slider-image'}
						image={item.image.sizes.medium2} 
					/>
				</Slide>
			)
		})
	}

	const renderCaption = (slide) => {
		if (!slide.caption) return;

		if (slide.caption_link) {
			return (
				<CaptionLink
					to={slide.caption_link}
					dangerouslySetInnerHTML={{__html: slide.caption}}
				/>
			)
		} else {
			return (
				<Caption
					dangerouslySetInnerHTML={{__html: slide.caption}}
				/>
			)
		}			
	}

	const renderControls = () => {
		if (!slides || !slides.length) return;
		const activeSlide = slides[activeIndex];

		return (
			<Controls>
				<Count>{activeIndex + 1}</Count>

				<PrevNext>
					<Prev
						onClick={() => swiper && swiper.slidePrev()}
					>
						Prev
					</Prev>
					
					<Divider>/</Divider>

					<Next
						onClick={() => swiper && swiper.slideNext()}
					>
						Next
					</Next>
				</PrevNext>

				{renderCaption(activeSlide)}
			</Controls> 	
		)
	}	

	return (
		<Wrapper
			sliderStyles={sliderStyles}
		>
			<Swiper
				{...swiperParams}
			>
				{props.renderSlides && props.renderSlides(slides) || renderSlides()}
			</Swiper>

			{controls && renderControls()}
		</Wrapper>
	)
}

// Shared

const Description = styled.div``
const InternalLink = styled(Link)``

const Image = styled.div`
	background-image: url(${props => props.image});  
	background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

// Layout

const defaultSliderStyles = css`
	height: 66vw;
	max-height: 900px;
`

const Wrapper = styled.div`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles}
	}
`

// Slide

const Slide = styled.div`
	${Image} {
		height: 100%;
	}
`

// Prev / Next

const Next = styled.div``
const Prev = styled.div``
const Divider = styled.div``

const PrevNext = styled.div`
	display: flex;
	user-select: none;

	${Divider} {
		margin: 0 7px;
	}

	${Next}, 
	${Prev} {
		cursor: pointer;
	}

	${media.phone`
		display: none;
	`}
` 

// Controls

const Count = styled.div``
const Caption = styled.div``
const CaptionLink = styled(Link)``

const Controls = styled.div`
	display: flex;
	margin-top: 30px;
	opacity: 0.4;

	${Count} {
		margin-right: 44px;
	}
	
	${Caption},
	${CaptionLink} {
		max-width: 50%;
		width: 100%;
		margin-left: auto;

		a {
			text-decoration: underline;
		}
	}

	${media.phone`
		font-size: 14px;
		margin-top: 15px;

		${Caption},
		${CaptionLink} {
			max-width: 70%;
		}
	`}
`

BlockSlider.wrapper = css``

export default BlockSlider
