import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { lightGrey } from '../../../styles/colors';

const BlockText = (props) => {
	const { content, articleBlock } = props;
        
	return (
		<Wrapper
			articleBlock={articleBlock}
		>
			<Text
				dangerouslySetInnerHTML={{__html: content}}  
			/>
		</Wrapper>
	)
}

const Text = styled.div``

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	strong, b {
		color: rgba(0,0,0,0.8);
	}

	${props => {
		if (!props.articleBlock) return css`
			${Text} {
				flex: 0 1 50%;

				${media.phone`
					flex: 1 0 100%;
				`}
			}
		`

		if (props.articleBlock) return css`
			${Text} {
				font-size: 18px;
				flex: 0 1 73%;
				
				${media.phone`
					flex: 1 0 100%;
				`}
				
				&, p {
					color: ${lightGrey};
				}
			}
		`
	}}
`



BlockText.wrapper = css`

`

export default BlockText
