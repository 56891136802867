import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils';

const Block = (props) => {
	const { layout, lastBlock, block_type, display_block, styles} = props;
	const BlockLayout = resolveBlock(layout);
	if (!BlockLayout ) return <></>  //|| !display_block

	return (
		<Wrapper
			className={`block_${layout}`}
			layout={layout}
			blockType={block_type}
			blockProps={props}
			styles={[styles, BlockLayout.wrapper]}
			lastBlock={lastBlock}
		>
			{BlockLayout && (
				<BlockLayout {...props}/>
			)}
		</Wrapper>
	)
}

export const blockPadding = css`
	margin-bottom: 65px;

	${media.phone`
		margin-bottom: 64px;
    `}

	${props => {
		if (props.blockProps && props.blockProps.articleBlock) return css`
			margin-bottom: 50px;

			${media.phone`
				margin-bottom: 30px;
			`}
		`
	}}
`

const Wrapper = styled.div`
    width: 100%;
	${blockPadding}
	
	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}
`

export default Block
