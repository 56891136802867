import { 
    BlockText,
    BlockHeading,
    BlockSlider,
    BlockMedia,
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'heading': BlockHeading,
        'image_slider': BlockSlider,
        'image': BlockMedia,
        'video': BlockMedia,
        
    }
    
    return blocks[layout]
}


