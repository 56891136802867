import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { grey, lightGrey } from './colors';
import { blockPadding } from '../components/Block/Block';

export const maxWidth = 1440;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        font-family: 'Neue Haas', Arial, Helvetica, sans-serif;
        font-size: 24px;
        letter-spacing: 0.12px;
        line-height: 1.21;
        -webkit-font-smoothing: antialiased;
        max-width: 100vw;
        /* overflow-x: hidden; */

        ${media.phone`
            font-size: 22px;
        `}
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        color: black;
    }

    p {
        margin-bottom: 0;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    p:first-child {
		margin-top: 0;
	}
`;

export const container = css`
    display: flex;
	height: 100%;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 42px; 
    padding-right: 42px;
    box-sizing: border-box;

    ${media.phone`
        padding-left: 22px; 
        padding-right: 22px;
    `}
`

export const margins = css`
    margin-left: 42px; 
    margin-right: 42px;

    ${media.phone`
        margin-left: 22px; 
        margin-right: 22px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

// Project Specific

export const button = css`
    height: 46px;
    font-size: 18px;
    letter-spacing: 0.005em;
    padding: 0 25px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 7px;
    padding-bottom: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease;

    &:hover {
        background: #C4C4C4;
    }
`


export const linkText = css`
    font-size: 18px;
    line-height: 1.21;
    letter-spacing: 0.09px;
    color: ${lightGrey};
`

export const heading = css`
    font-size: 36px;
    line-height: 1.21;

    ${media.phone`
        font-size: 32px;
    `}
`
export const headingText = css`
    display: flex;
    justify-content: space-between;
    ${blockPadding}

    /* Heading */

    > *:first-child {
        max-width: calc(50% - 100px);    
        ${heading}
    }

    /* Info */

    > *:nth-child(2) { 
        display: flex;
        flex-direction: column;
        max-width: 50%;

        > *:nth-child(2) {
            margin-top: 48px;
        }
    }

    ${media.phone`
        flex-direction: column;

        > *:first-child {
            max-width: none;
        }

        > *:nth-child(2) { 
            max-width: none;
            margin-top: 48px;

            > *:nth-child(2) {
                margin-top: 38px;
            }
        }
    `}
`
export const absoluteCover = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`
