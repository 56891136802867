import React from 'react'
import { navigate, Link } from 'gatsby-link'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import ReactPlayer from 'react-player'

import { media, isClient } from '../../../styles/utils'
import { container, padding, hoverState, bgImage, bgIcon } from '../../../styles/global'
import { useSetState } from 'react-use';

const BlockMedia = (props) => {
	const { media, layout, image, video, src, block_type } = props;	
	
	const [playerState, setPlayerState] = useSetState({
		muted: false,
		playing: false,
	});
		
	const renderCaption = () => {
		const { caption, use_caption } = props;
		if (!use_caption) return

		return (
			<Caption
				articleBlock={props.articleBlock}
				dangerouslySetInnerHTML={{__html: caption}}  
			/>
		)
	}
	
	const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe.match(/src="(.+?)"/)[1]
		}
	}

	const renderControls = () => {
		const { playing, muted } = playerState;
		
		return (
			<Controls>
				<PlayPause
					onClick={() => setPlayerState({playing: !playing})}
				>
					{playing ? 'Pause' : 'Play'}
				</PlayPause>
				<Sound
					onClick={() => setPlayerState({muted: !muted})}
				>
					{muted ? 'Sound' : 'Mute'}
				</Sound>
			</Controls>
		)
	}
	
	const renderMedia = () => {
		return (
			<>
				{layout == 'image' && image.sizes && (
					<Image
						key={image.id}
						src={image.sizes.medium2}
					>
						{(src, loading) => {
							return (
								<>
									<Fade
										style={{opacity: loading ? 1 : 0}}
									/>
									<BGImage
										image={src}
										style={{opacity: loading ? 0 : 1}}
									/>
								</>
							)
						}}
					</Image>
				)}

				{layout == 'video' && video && (
					<VideoWrapper>
						<Video
							url={getVideoURL(video)}
							className={'media'}
							volume={1}
							muted={playerState.muted}
							playing={playerState.playing}
						/>

						{renderControls()}
					</VideoWrapper>
				)}

		
				{renderCaption()}
			</>
		)
	}
	
	return (	
		<Container
			type={block_type}
			articleBlock={props.articleBlock}
		>
			{renderMedia()}
		</Container>
	)
}


// Image

const Fade = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`


const BGImage = styled.div`
	background-image: url(${props => props.image});
	transition: opacity 0.45s ease;
	height: 100%;
	width: 100%;
	z-index: 2;
	position: relative;
	background-size: cover;
	background-position: center center;
`

const VideoWrapper = styled.div`
	position: relative;
`

const Video = styled(ReactPlayer)`
	width: 100% !important;
`

// Layout

const Container = styled.div`
	width: 100%;
	position: relative;
	
	${Fade}, 
	${BGImage}, 
	${Video} {
		height: 66vw !important;
		max-height: 770px;
	}

	${Video} {
		background: black;
		overflow: hidden;
	}

	${Fade} {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}

	${props => {
		if (props.articleBlock) return css`
			${Fade}, 
			${BGImage}, 
			${Video} {
				height: 580px !important;
				
				${media.phone`
					height: 273px !important;
				`}
			}

			${Video} {
				height: 470px !important;

				${media.phone`
					height: 187px !important;
				`}
			}
		`
	}}
`

// Caption

const Caption = styled.div`
	display: flex;
	width: 100%;
	max-width: 50%;
	width: 100%;
	margin-left: auto;
	opacity: 0.4;
	margin-top: 30px;

	${props => {
		if (props.articleBlock) return css`
			font-size: 18px;
			max-width: 73%;
		`
	}}

	${media.phone`
		font-size: 14px;
		margin-top: 20px;
		max-width: 70%;
	`}
`

// Controls

const Sound = styled.div``
const PlayPause = styled.div``

const Controls = styled.div`
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 2;
	display: flex;
	user-select: none;
	mix-blend-mode: screen;

	${Sound},
	${PlayPause} {
		color: white;
		cursor: pointer;
	}

	${PlayPause} {
		margin-right: 126px;
	}

	@supports not (mix-blend-mode: multiply) {
		${Sound},
		${PlayPause} {
			text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
		}
	}

	${media.phone`
		font-size: 18px;

		${Sound} {
			display: none;
		}

		${PlayPause} {
			margin-right: 24px;
		}
	`}
`

BlockMedia.wrapper = css`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export default BlockMedia
