import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, heading } from '../../../styles/global'

const BlockHeading = (props) => {
	const { content, articleBlock } = props;
        
	return (
		<Wrapper
			articleBlock={articleBlock}
		>
			<Heading
				dangerouslySetInnerHTML={{__html: content}}  
			/>
		</Wrapper>
	)
}

const Heading = styled.div`
	font-size: 36px;
    line-height: 1.21;

    ${media.phone`
        font-size: 32px;
    `}
`

const Wrapper = styled.div`
	display: flex;
	width: 100%;

	${props => {
		if (!props.articleBlock) return css`
			${Heading} {
				flex: 0 1 50%;

				${media.phone`
					flex: 1 0 100%;
				`}
			}
		`
	}}

	${props => {
		if (props.articleBlock) return css`
			justify-content: flex-end;

			${Heading} {
				flex: 0 1 73%;
				font-size: 24px;

				${media.phone`
					flex: 1 0 100%;
				`}
			}
		`
	}}
`

BlockHeading.wrapper = css`
	margin-bottom: 130px;

	${props => {
		if (props.blockProps.articleBlock) return css`
			margin-top: 75px;
			margin-bottom: 24px;

			${media.phone`
				margin-top: 32px;
				margin-bottom: 24px;
			`}
		`
	}}
`

export default BlockHeading
